import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Bullet from "../../../../../components/bullet"
import BulletList from "../../../../../components/bullet-list"
import Paper from "../../../../../components/paper"
import Textarea from "../../../../../components/textarea"
import HalfAssedBox from "../../../../../components/half-assed-box"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import RotateLetter from "../../../../../components/rotate-letter"
import Note from "../../../../../components/note"

const Step = ({ title, number, children }) => {
  return (
    <HalfAssedBox>
      <Columns collapse={[true, false]}>
        <Column width="content">
          <RotateLetter>{number}</RotateLetter>
        </Column>
        <Column>
          <Stack space={6}>
            <Heading as="h3" level={4}>
              {title}
            </Heading>
            {children}
          </Stack>
        </Column>
      </Columns>
    </HalfAssedBox>
  )
}

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "02-entstehungsgeschichte",
          taskId: "gedichtrezension",
        })
        navigate(
          "/kurse/haelfte-des-lebens/02-entstehungsgeschichte/gedichtrezension/zusammenfassung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Eine Gedichtrezension verfassen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Eine Gedichtrezension verfassen
            </Heading>
          </Stack>
          <Paragraph>
            Nun bist du selbst gefragt: Schreibe eine eigene Bewertung zu dem
            Gedicht. In welche Richtung es geht, ist dir selbst überlassen.
            Schließt du dich den zeitgenössischen Rezensionen an oder machst du
            dich an eine Ehrenrettung des Gedichts?
          </Paragraph>
          <Step number={1} title="Einleitung">
            <Paragraph>
              In der Einleitung stellst du das Gedicht kurz vor. Folgende
              Schritte sind dabei wichtig:
            </Paragraph>
            <Paper p={[3, 4, 6]}>
              <BulletList size={[2, 2, 3, 4]}>
                <Bullet>
                  Wer ist der Autor? Was muss man von ihm wissen, um das Gedicht
                  zu verstehen?
                </Bullet>
                <Bullet>
                  Um was geht es – ganz oberflächlich? Hier reicht ein Satz.
                </Bullet>
                <Bullet>
                  Welchem Genre ist das Gedicht zuzuordnen. Um welche Art von
                  Gedicht handelt es sich?
                </Bullet>
                <Bullet>Hintergründe zur Entstehung des Gedichts.</Bullet>
              </BulletList>
            </Paper>
            <Paragraph>
              Die Einleitung sollte relativ neutral gehalten sein. Sie kann aber
              bereits mit einem knackigen ersten Satz begonnen werden: Mit einer
              steilen These, einer provokanten Aussage oder einer Frage, die
              erst zum Ende hin beantwortet wird. Dabei soll Neugierde geweckt
              werden.
            </Paragraph>
          </Step>
          <Step number={2} title="Hauptteil">
            <Paragraph>
              Anschließend geht es ans Eingemachte: Im Hauptteil kommt deine
              Bewertung des Gedichts hinzu. du beschreibst, was dir daran
              gefällt – und was nicht. Aspekte, die hier mit bewertet werden
              sollten:
            </Paragraph>
            <Paper p={[3, 4, 6]}>
              <BulletList size={[2, 2, 3, 4]}>
                <Bullet>
                  Der Aufbau des Gedichts: Wie ist das Gedicht konstruiert?
                  Macht das Sinn? Passt das zum Thema des Gedichts?
                </Bullet>
                <Bullet>
                  Das Thema: Ist das Thema außergewöhnlich und aktuell? Spricht
                  es dich an?
                </Bullet>
                <Bullet>
                  Die Motive: Welche Motive, Symbole und Metaphern kommen darin
                  vor? Wie würdest du sie bewerten?
                </Bullet>
                <Bullet>
                  Die Sprache: Sind die Worte gut gewählt? Ist die Sprache
                  schwer oder leicht verständlich? Findest du das gut oder
                  schlecht?
                </Bullet>
                <Bullet>
                  Der Stil: Was zeichnet den Stil des Autors aus? Gibt es etwas,
                  was ihn von anderen unterscheidet?
                </Bullet>
              </BulletList>
            </Paper>
            <Paragraph>
              Dabei kannst du sowohl positive als auch negative Bewertungen mit
              einfließen lassen. Auch Ironie und Satire sind erlaubt. Zugleich
              sollte den Leser*innen die Möglichkeit gegeben werden, sich auch
              selbst eine eigene Meinung zu bilden. Zur Veranschaulichung können
              Zitate aus dem Gedicht hinzugezogen werden. Auch eigene
              Interpretationen und Auslegungen dürfen hier mit einfließen.
            </Paragraph>
          </Step>
          <Step number={3} title="Schluss">
            <Paragraph>
              Im Schlussteil wird ein Fazit gezogen. Du wägst die Argumente noch
              einmal ab und entscheidest, ob und wem du das Gedicht empfehlen
              würdest.
            </Paragraph>
            <Paper p={[3, 4, 6]}>
              <BulletList size={[2, 2, 3, 4]}>
                <Bullet>
                  Ziehe Vergleiche zu einem Werk, an das dich das Gedicht
                  erinnert.
                </Bullet>
                <Bullet>
                  An welche aktuellen oder allgemeinen Themen und Probleme
                  knüpft das Gedicht an?
                </Bullet>
                <Bullet>
                  Überlege, ob du in dem Gedicht deine bisherige Einschätzung
                  des Autors bestätigt findest oder korrigieren musst?
                </Bullet>
                <Bullet>
                  Gibt es etwas, was du selbst nicht verstanden hast?
                </Bullet>
                <Bullet>
                  Welche weiteren Deutungsmöglichkeiten gibt es für das Gedicht?
                </Bullet>
                <Bullet>
                  Wie würdest du das Gedicht abschließend bewerten?{" "}
                </Bullet>
              </BulletList>
            </Paper>
          </Step>

          <Note variant="task">Schreibe hier deine eigene Bewertung.</Note>

          <Textarea
            id="intro"
            name="intro"
            label="Einleitung: Stell das Gedicht kurz vor"
            rows={8}
          />
          <Textarea
            id="main"
            name="main"
            label="Hauptteil: Was gefällt dir am Gedicht – und was nicht?"
            rows={8}
          />
          <Textarea
            id="end"
            name="end"
            label="Schluss: Zeit für dein Fazit"
            rows={8}
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
